<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" :style="bg_style" to="/">
      <img :src="logo" style="max-height: 46px; width: auto; max-width: 100%;"/>
      <!--
            <CIcon
              class="c-sidebar-brand-full"
              name="logo"
              size="custom-size"
              :height="35"
          viewBox="0 0 556 134"
            />
            <CIcon
              class="c-sidebar-brand-minimized"
              name="logo"
              size="custom-size"
              :height="35"
              viewBox="0 0 110 134"
            />-->
      <!--<span style="font-size: 18px"> Аналитика маркетплейсов</span>-->
    </CSidebarBrand>

    <!--<CRenderFunction flat :content-to-render="tnav"/>-->
    <CSidebarNav>
      <template v-for="item in tnav[0]._children">
        <!--<li class="c-sidebar-nav-title" v-if="item._name==='CSidebarNavTitle'" :key="item.name">{{item.name}}</li>-->
        <CSidebarNavTitle v-if="item._name==='CSidebarNavTitle'" :key="item.name">{{item.name}}</CSidebarNavTitle>
        <CSidebarNavItem v-else :key="item.name" :name="item.name" :to="item.to" :fontIcon="item.icon"/>
      </template>
    </CSidebarNav>
    <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    logo: function () {
      let user = this.$store.state.User;
      if (user.partner.type) {
        return `/img/departTypes/${user.partner.type}/logo.png`;
      } else {
        return `/img/logo_mp.png`;
      }
    },
    bg_style: function () {
      let user = this.$store.state.User;
      if (user.partner.type == 13 || user.partner.type == 18 || user.partner.type == 21 || user.partner.type == 25|| user.partner.type == 28) {
        return `background: #fff;`;
      } else {
        return ``;
      }
    },
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    tnav() {
      return this.$store.state.sidebar_elems;
    }
  }
}
</script>
